<template>
  <div class="oap-toc__wrapper">
    <slot />
  </div>
</template>

<script>
import { globalResizeObserver } from '@Foundation/utilities/globalResizeObserver';

export default {
  name: 'OapTableOfContents',

  data() {
    return {
      tabIndex: 0,
    };
  },
  mounted() {
    this.toggleTabIndex();

    globalResizeObserver.observe(this.$el, this.toggleTabIndex, 200);
  },
  beforeDestroy() {
    globalResizeObserver.unobserve(this.$el);
  },
  methods: {
    toggleTabIndex() {
      const windowWidth = this.$el.clientWidth;
      const titleElement = this.$el.querySelector('.oap-toc__title');
      const detailsElement = this.$el.querySelector('details');

      if (windowWidth >= 999) {
        titleElement.setAttribute('tabindex', -1);
        detailsElement.setAttribute('open', '');
      } else {
        titleElement.setAttribute('tabindex', 0);
      }
    },
  },
};
</script>
